import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { storeEntity } from '../../services/httpService';
import { JWT_TOKEN } from '../../config/config';
import { toaster } from '../../utils/helper';
import { setLocalStorage } from '../../utils/storageUtil';
import { Navigate } from 'react-router-dom';

const initData = {
    pre_heading: "Login",
    heading: "Login to your Account",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit."
}

const socialData = [
    {
        id: "1",
        link: "facebook",
        icon: "fab fa-facebook-f"
    },
    {
        id: "2",
        link: "twitter",
        icon: "fab fa-twitter"
    },
    {
        id: "3",
        link: "google-plus",
        icon: "fab fa-google-plus-g"
    }
]

class Login extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: socialData
        }, () => {
            this.props.loaderCallback()
        })
    }
    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = this.state
        let { emailError, passwordError } = ''
        if (!email) {
            emailError = "This field is required."
        }
        if (!password) {
            passwordError = "This field is required."
        }
        this.setState({
            emailError: emailError,
            passwordError: passwordError
        })
        if (emailError || passwordError) {
            return
        }
        const response = await storeEntity('/auth/login', { email, password })
        console.log(response)
        if (response.status === 200) {
            setLocalStorage(JWT_TOKEN, response.data.data.token)
            setLocalStorage('user', response.data.data)
            this.setState({
                redirect: '/'
            })
        } else {
            toaster('error', "These credentials do not match our records.")
        }
    }
    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <section className="author-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>{this.state.initData.pre_heading}</span>
                                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                                <p>{this.state.initData.content}</p>
                            </div>
                            {/* Item Form */}
                            <form className="item-form card no-hover" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="email" className="form-control" name="email" onChange={this.handleChange} placeholder="Enter your Email" required="required" />
                                            <span className='error text-danger'>{this.state.emailError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="password" className="form-control" name="password" onChange={this.handleChange} placeholder="Enter your Password" required="required" />
                                            <span className='error text-danger'>{this.state.passwordError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" defaultChecked />
                                                <label className="form-check-label" htmlFor="inlineRadio1">Remember Me</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" type="submit">Sign In</button>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-block text-center mt-4">Don't have an account yet?  <Link to="/signup">Sign Up</Link></span>
                                    </div>
                                    {/* <div className="col-12">
                                        <hr />
                                        <div className="other-option">
                                            <span className="d-block text-center mb-4">Or</span>
                                            <div className="social-icons d-flex justify-content-center">
                                                {this.state.data.map((item, idx) => {
                                                    return (
                                                        <a key={`lsd_${idx}`} className={item.link} href="#">
                                                            <i className={item.icon} />
                                                            <i className={item.icon} />
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Login;