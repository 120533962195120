import React, { Component } from 'react';
import MintNFT from "../components/mint/MintNFT"
class Home extends Component {
    render() {
        return (
            <MintNFT />
        );
    }
}

export default Home;