import React, { Component } from 'react';

import Loader from './loader';
import VerifyEmailComp from '../components/VerifyEmail/VerifyEmailComp';
import ToasterComp from '../components/Toaster/ToasterComp';

class VerifyEmail extends Component {
    state={
        loading:true
    }
    loader = () =>{
        this.setState({
            loading:!this.state.loading
        })
    }
    render() {
        return (
            <div className="main">
                <ToasterComp />
                <Loader class={this.state.loading}/>
                <VerifyEmailComp loaderCallback={this.loader}/>
            </div>
        );
    }
}

export default VerifyEmail;