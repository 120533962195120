import jwtDecode from 'jwt-decode'

import { getLocalStorage } from './storageUtil'
import { JWT_TOKEN, WALLET_ACCOUNT } from '../config/config'

export let isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000

  } catch (e) {
    return false
  }
}

export let getToken = () => {
  return getLocalStorage(JWT_TOKEN)
}

export let getAccount = () => {
  return getLocalStorage(WALLET_ACCOUNT)
}

export let isAuthenticated = () => {
  return !!getToken() && !isTokenExpired(getToken())
}

