import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fetchEntity } from '../../services/httpService';
import { storeEntity } from '../../services/httpService';
import { Navigate } from 'react-router-dom';
import { toaster } from '../../utils/helper';

const initData = {
    pre_heading: "Signup",
    heading: "Create an Account",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit."
}

const socialData = [
    {
        id: "1",
        link: "facebook",
        icon: "fab fa-facebook-f"
    },
    {
        id: "2",
        link: "twitter",
        icon: "fab fa-twitter"
    },
    {
        id: "3",
        link: "google-plus",
        icon: "fab fa-google-plus-g"
    }
]

class Signup extends Component {
    state = {
        initData: {},
        data: [],
        error: {},
        redirect: ""
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: socialData
        },()=>{
            this.props.loaderCallback()
        })
    }
    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
        if(name === 'email'){
            this.checkEmail(value);
        }
    }
    checkEmail = async(val) =>{
        const response = await fetchEntity('/checkEmail',{email:val})
        if(response.status){

        }
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        const { first_name, last_name, email, password } = this.state
        let { firstNameError, lastNameError, emailError, passwordError } = ''
        if (!first_name) {
            firstNameError = "This field is required."
        }
        if (!last_name) {
            lastNameError = "This field is required."
        }
        if (!email) {
            emailError = "This field is required."
        }
        if (!password) {
            passwordError = "This field is required."
        }
        this.setState({
            error: {
                firstNameError: firstNameError,
                lastNameError: lastNameError,
                emailError: emailError,
                passwordError: firstNameError,
            }
        })
        if (firstNameError || lastNameError || emailError || passwordError) {
            return
        }
        localStorage.setItem('email', email)
        const response = await storeEntity('/auth/register', { first_name, last_name, email, password })
        if (response.data.status === 200) {
            localStorage.setItem('verifyRedirect', "/login")
            this.setState({
                redirect:"/verify-email"
            })
        }else if(response.data.status === 400){
            toaster("error", response.data.message)
        }else{
            toaster("error", "Something went wrong, please try again")
        }

    }
    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <section className="author-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>{this.state.initData.pre_heading}</span>
                                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                                <p>{this.state.initData.content}</p>
                            </div>
                            {/* Item Form */}
                            <form onSubmit={this.handleSubmit} className="item-form card no-hover">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="first_name" onChange={this.handleChange} placeholder="Enter your First Name" required="required" />
                                            <span className='error text-danger'>{this.state.error.firstNameError}</span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="last_name" onChange={this.handleChange} placeholder="Enter your Last Name" required="required" />
                                            <span className='error text-danger'>{this.state.error.lastNameError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="email" className="form-control" name="email" onChange={this.handleChange} placeholder="Enter your Email" required="required" />
                                            <span className='error text-danger'>{this.state.error.emailError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="password" className="form-control" name="password" onChange={this.handleChange} placeholder="Enter your Password" required="required" />
                                            <span className='error text-danger'>{this.state.error.passwordError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
                                                <label className="form-check-label" htmlFor="inlineRadio1">I agree to <a href="#">Privacy Policy</a></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" type="submit">Sign Up</button>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-block text-center mt-4">Already have an account? <Link to="/login">Login</Link></span>
                                    </div>
                                    {/* <div className="col-12">
                                        <hr />
                                        <div className="other-option">
                                            <span className="d-block text-center mb-4">Or</span>
                                            <div className="social-icons d-flex justify-content-center">
                                                {this.state.data.map((item, idx) => {
                                                    return (
                                                        <a key={`lsd_${idx}`} className={item.link} href="#">
                                                            <i className={item.icon} />
                                                            <i className={item.icon} />
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Signup;