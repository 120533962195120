import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, Slide } from 'react-toastify';

class ToasterComp extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount() {
    }
    render() {
        return (
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Slide}
            />
        );
    }
}

export default ToasterComp;