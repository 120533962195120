import React, { Component } from 'react';
import Slider from "react-slick";

const initData = {
    pre_heading: "Auctions",
    heading: "Live Auctions",
    btnText: "View All"
}

const data = [
    {
        id: "1",
        img: "/img/auction_1.jpg",
        date: "2021-12-09",
        title: "Virtual Worlds",
        seller_thumb: "/img/avatar_1.jpg",
        seller: "@Richard",
        price: "1.5 BNB",
        count: "1 of 1"
    },
    {
        id: "2",
        img: "/img/auction_2.jpg",
        date: "2021-10-05",
        title: "Collectibles",
        seller_thumb: "/img/avatar_2.jpg",
        seller: "@JohnDeo",
        price: "2.7 BNB",
        count: "1 of 1"
    },
    {
        id: "3",
        img: "/img/auction_3.jpg",
        date: "2021-09-15",
        title: "Arts",
        seller_thumb: "/img/avatar_3.jpg",
        seller: "@MKHblots",
        price: "2.3 BNB",
        count: "1 of 1"
    },
    {
        id: "4",
        img: "/img/auction_4.jpg",
        date: "2021-12-29",
        title: "Robotic Arts",
        seller_thumb: "/img/avatar_4.jpg",
        seller: "@RioArham",
        price: "1.8 BNB",
        count: "1 of 1"
    },
    {
        id: "5",
        img: "/img/auction_5.jpg",
        date: "2022-01-24",
        title: "Design Illusions",
        seller_thumb: "/img/avatar_5.jpg",
        seller: "@ArtNox",
        price: "1.7 BNB",
        count: "1 of 1"
    },
    {
        id: "6",
        img: "/img/auction_6.jpg",
        date: "2022-03-30",
        title: "Photography",
        seller_thumb: "/img/avatar_6.jpg",
        seller: "@Junaid",
        price: "3.5 BNB",
        count: "1 of 1"
    }
]

class AuctionsOne extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: data
        })
    }
    liveAuctionSlide = () => {
        if (this.state.data) {
            return this.state.data.map((item, idx) => {
                return (
                    <div key={`auc_${idx}`} className="item">
                        <div className="card m-2">
                            <div className="image-over">
                                <a href="/item-details">
                                    <img className="card-img-top" src={item.img} alt="" />
                                </a>
                            </div>
                            {/* Card Caption */}
                            <div className="card-caption col-12 p-0">
                                {/* Card Body */}
                                <div className="card-body">
                                    <div className="countdown-times mb-3">
                                        <div className="countdown d-flex justify-content-center" data-date={item.date} />
                                    </div>
                                    <a href="/item-details">
                                        <h5 className="mb-0">{item.title}</h5>
                                    </a>
                                    <a className="seller d-flex align-items-center my-3" href="/item-details">
                                        <img className="avatar-sm rounded-circle" src={item.seller_thumb} alt="" />
                                        <span className="ml-2">{item.seller}</span>
                                    </a>
                                    <div className="card-bottom d-flex justify-content-between">
                                        <span>{item.price}</span>
                                        <span>{item.count}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        }
    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section className="live-auctions-area">
                <div className="container">
                    <div className="auctions-slides">
                        <Slider {...settings}>
                            {this.liveAuctionSlide()}
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

export default AuctionsOne;