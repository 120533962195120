import React from 'react';
import { hot } from 'react-hot-loader';

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default hot(module)(App);