import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Creates from '../components/Create/Create';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Loader from './loader';

class Create extends Component {
    state={
        loading:true
    }
    loader = () =>{
        console.log(this.state.loading)
        this.setState({
            loading:!this.state.loading
        })
    } 
    render() {
        return (
            <div className="main">
                <Loader class={this.state.loading}/>
                <Header />
                <Breadcrumb title="Create" subpage="Pages" page="Create" />
                <Creates loaderCallback={this.loader}/>
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default Create;