import axios from 'axios'

import {API_URL, JWT_TOKEN} from '../config/config'
import {getLocalStorage} from './storageUtil'

export const httpBase = () => {
    const api = axios.create({
        baseURL: `${API_URL}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getLocalStorage(JWT_TOKEN)}`
        },
        responseType: 'json'
    })

    api.interceptors.response.use((response) => {
        return response;
    }, error => {
        return error.response
        if (401 === error.response.status) {
            // redirect to login page
        }
        if (404 === error.response.status) {
            // redirect to 404 page
        }
        if (500 === error.response.status) {
            // redirect to 500 page
        }
        console.log(error)
        return error

    })

    return api
}