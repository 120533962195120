import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { storeEntity } from '../../services/httpService';
import { JWT_TOKEN } from '../../config/config';
import { toaster } from '../../utils/helper';
import { setLocalStorage } from '../../utils/storageUtil';
import { Navigate } from 'react-router-dom';

const initData = {
    pre_heading: "Email Verification",
    heading: "Please enter your Email",
    content: ""
}

const socialData = [
    {
        id: "1",
        link: "facebook",
        icon: "fab fa-facebook-f"
    },
    {
        id: "2",
        link: "twitter",
        icon: "fab fa-twitter"
    },
    {
        id: "3",
        link: "google-plus",
        icon: "fab fa-google-plus-g"
    }
]

class EmailComp extends Component {
    state = {
        initData: {},
        data: [],
        email:"",
        emailError: "",
        redirect:""
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: socialData
        }, () => {
            this.props.loaderCallback()
        })
    }
    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        const { email } = this.state
        let emailError = ""
        if (!email) {
            emailError = "This field is required."
        }
        this.setState({
            emailError: emailError
        })
        if (emailError) {
            return
        }
        localStorage.setItem("email",email)
        this.setState({
            redirect:"/verify-email"
        })
    }
    render() {
        if(this.state.redirect){
            return <Navigate to={this.state.redirect} />
        }
        return (
            <section className="author-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>{this.state.initData.pre_heading}</span>
                                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                                <p>{this.state.initData.content}</p>
                            </div>
                            {/* Item Form */}
                            <form className="item-form card no-hover" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="email" className="form-control" name="email" onChange={this.handleChange} placeholder="Enter your Email" required="required" />
                                            <span className='error text-danger'>{this.state.emailError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default EmailComp;