import React from "react";
import Web3 from "web3";
import BN from 'bn.js'
import ERC20 from './abi-erc20.json'
import BigNumber from 'bignumber.js'
import { PROVIDER, CONTRACT_ADDRESS } from "../constants/entity"
import detectEthereumProvider from '@metamask/detect-provider';
import { storeEntity } from "../services/httpService";
import { toaster } from "../utils/helper";
import { isObject } from "../utils/commonUtil";
import { Alert } from "react-bootstrap";

const etherum = window.ethereum
const web3 = new Web3(PROVIDER);
const contract = new web3.eth.Contract(ERC20, CONTRACT_ADDRESS);
const networks = {
    polygon: {
        chainId: `0x${Number(137).toString(16)}`,
        chainName: "Polygon Mainnet",
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
        },
        rpcUrls: ["https://polygon-rpc.com/"],
        blockExplorerUrls: ["https://polygonscan.com/"]
    },
    bsc: {
        chainId: `0x${Number(56).toString(16)}`,
        chainName: "Binance Smart Chain Mainnet",
        nativeCurrency: {
            name: "Binance Chain Native Token",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: [
            "https://bsc-dataseed1.binance.org",
            "https://bsc-dataseed2.binance.org",
            "https://bsc-dataseed3.binance.org",
            "https://bsc-dataseed4.binance.org",
            "https://bsc-dataseed1.defibit.io",
            "https://bsc-dataseed2.defibit.io",
            "https://bsc-dataseed3.defibit.io",
            "https://bsc-dataseed4.defibit.io",
            "https://bsc-dataseed1.ninicoin.io",
            "https://bsc-dataseed2.ninicoin.io",
            "https://bsc-dataseed3.ninicoin.io",
            "https://bsc-dataseed4.ninicoin.io",
            "wss://bsc-ws-node.nariox.org"
        ],
        blockExplorerUrls: ["https://bscscan.com"]
    },
    bsc_testnet: {
        chainId: `0x${Number(97).toString(16)}`,
        chainName: "Binance Smart Chain Testnet",
        nativeCurrency: {
            name: "Binance Chain Native Token",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: [
            "https://data-seed-prebsc-1-s1.binance.org:8545"
        ]
    }
}
const changeNetwork = async () => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        ...networks['bsc']
                    },
                ],
            })
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId === `0x${Number(56).toString(16)}`) {
                return await MetamaskConnect()
            }
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['bsc_testnet']
                            },
                        ],
                    });
                } catch (addError) {
                    toaster("error", addError.message)
                }
            }
            // handle other "switch" errors
            toaster("error", switchError.message)
        }
    } else {
        toaster("error", "Metamask extension not installed.")
    }
};
const MetamaskConnect = () => {

    return new Promise((resolve, reject) => {
        etherum
            .request({ method: 'eth_requestAccounts' })
            .then((val) => {
                resolve(val)
            })
            .catch((err) => {
                console.log(err)
                if (err.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    // If this happens, the user rejected the connection request.
                    toaster("error", err.message);
                    reject(err.message)
                } else {
                    console.error(err);
                    reject(err.message)
                }
            });
    })
    // return etherum
    //     .request({ method: 'eth_requestAccounts' })
    //     .then((val) => {
    //         // updateUserWalletAddress(val)
    //         // console.log(val)
    //         return val
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //         if (err.code === 4001) {
    //             // EIP-1193 userRejectedRequest error
    //             // If this happens, the user rejected the connection request.
    //             toaster("error", err.message);
    //         } else {
    //             console.error(err);
    //         }
    //     });
}
const updateUserWalletAddress = async (accounts) => {
    const response = await storeEntity('wallet-details', { web3Address: accounts[0], web3Provider: "Metamask" });
    if (response.data.status === 200) {
        return true
    }
    return false
}

export const isWalletConnected = () => {
    try {
        const address = localStorage.getItem("web3Address")
        if (address) {
            return true
        }
        return false

    } catch (error) {
        return false
    }
}

export const MetamaskConnection = async () => {
    if (typeof window.ethereum !== 'undefined') {
        console.log("web3 provide detected")
        // reload the page
        return await changeNetwork();
    } else {
        alert("Please install metamask")
    }
    // console.log("change Net", await changeNetwork())
}

export const getAccount = async () => {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    return account
}

export const getAccountBalance = async () => {
    const balance = await contract.methods.balanceOf(await getAccount()).call();
    const balanceWeiBN = new BN(balance)
    const decimals = await contract.methods.decimals().call();
    const bn = new BigNumber(balance + "e-" + decimals);
    return bn.toString();
}

export const getSymbol = async () => {
    const symbol = await contract.methods.symbol().call();
    return symbol;
}

export const checkConnected = async () => {
    const account = web3.eth.accounts.currentProvider
    console.log(web3.eth.getAccounts(accounts => console.log(accounts)));
    return account
}


export const updateUserData = () => {
    const web3 = new Web3(window.ethereum);
    web3.eth.getAccounts(function (err, accounts) {
        if (err != null) console.error("An error occurred: " + err);
        else if (accounts.length == 0) console.log("User is not logged in to MetaMask");
        else {

        };
    });

}

const addUserAddress = async () => {
    let { address, type, balance } = ''
    address = await getAccount();
    type = "Metamask"
    balance = await getAccountBalance();
    if (address && type && balance) {
        const response = await storeEntity('add-account', { address: address, type: type });
        if (response.status === 200) {
            addUserBalance(response)
        }
    }
}

const addUserBalance = async (val) => {
    let { symbol, amount, address } = ''
    address = await getAccount()
    symbol = await getSymbol()
    amount = await getAccountBalance()
    if (symbol && amount) {
        const response = await storeEntity('add-account-balance', { address: address, symbol: symbol, amount: amount });
        if (response.status === 200) {
            console.log(response)
        }
    }
}

// Runs whenever the user changes account state
// window.ethereum.on('accountsChanged', async (val) => {
//     if (val.length === 0) {
//         localStorage.clear()
//         window.location.reload()
//     }
// });