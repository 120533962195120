import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { storeEntity } from '../../services/httpService';
import { JWT_TOKEN } from '../../config/config';
import { toaster } from '../../utils/helper';
import { setLocalStorage } from '../../utils/storageUtil';
import { Navigate } from 'react-router-dom';

const initData = {
    pre_heading: "Email Verification",
    heading: "Please verify your Account",
    content: ""
}

const socialData = [
    {
        id: "1",
        link: "facebook",
        icon: "fab fa-facebook-f"
    },
    {
        id: "2",
        link: "twitter",
        icon: "fab fa-twitter"
    },
    {
        id: "3",
        link: "google-plus",
        icon: "fab fa-google-plus-g"
    }
]

class VerifyEmailComp extends Component {
    state = {
        initData: {},
        data: [],
        verifyCode: "",
        verifyCodeError: "",
        redirect: ""
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: socialData
        }, () => {
            this.props.loaderCallback()
        })
    }
    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        const { verifyCode } = this.state
        let { verifyCodeError, emailError } = ''
        const email = localStorage.getItem("email")
        if (!verifyCode) {
            verifyCodeError = "This field is required."
        }
        this.setState({
            verifyCodeError: verifyCodeError
        })
        if (verifyCodeError && !email) {
            return
        }
        const response = await storeEntity('/auth/verify', { email, code:verifyCode })
        console.log(response)
        if (response.status === 200) {
            this.setState({
                redirect: '/login'
            })
        
        }else if (response.status === 404) {
            toaster('error', response.data.message)

        } else {
            toaster('error', "Something went wrong please try again.")
        }
    }
    resendEmailVerification = async () => {
        const email = localStorage.getItem("email")
        if (email) {
            const response = await storeEntity('/auth/generate-otp', { email })
            if (response.data.status === 200) {
                toaster("success", "Email successfully sent.")
            } else {
                if(response.data.status === 404){
                    toaster("error", response.data.message)
                    setTimeout(
                        function () {
                            this.setState({
                                redirect:"/signup"
                            })
                        }
                            .bind(this),
                        3000
                    );
                                 
                }
            }
        } else {
            toaster("error", "Something went wrong please try again.")
        }

    }
    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <section className="author-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>{this.state.initData.pre_heading}</span>
                                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                                <p>{this.state.initData.content}</p>
                            </div>
                            {/* Item Form */}
                            <form className="item-form card no-hover" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="number" className="form-control" name="verifyCode" onChange={this.handleChange} placeholder="Enter your Email Verification Code" required="required" />
                                            <span className='error text-danger'>{this.state.verifyCodeError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" type="submit">Verify</button>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-block text-center mt-4">I did not receive the verification email?  <Link to="" onClick={() => this.resendEmailVerification()}>Resend</Link></span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default VerifyEmailComp;