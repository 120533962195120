import React, { Component } from 'react';
import AuthorProfile from "../AuthorProfile/AuthorProfile";
import { clientIPFS } from '../../utils/helper';
import { ethers } from "ethers";
import Web3 from "web3";
import { MARKET_PLACE_ADDRESS, ERC721_ADDRESS } from '../../constants/entity';
import MarketPlaceAbi from "../../context/abi-erc20.json"
import ERC721_ABI from "../../context/ERC721_ABI.json"

class Create extends Component {
    state = {
        imageUrl: "img/image.png",
        name: "",
        description: "",
        royalityError: false,
        royality: 0,
        copies: 0,
        price: 0
    }
    componentDidMount() {
        this?.props?.loaderCallback()
    }
    onFileUpload = async (e) => {
        const file = e.target.files[0]
        this?.props?.loaderCallback()
        try {
            const added = await clientIPFS.add(
                file,
                {
                    progress: (prog) => console.log(`received: ${prog}`)
                }
            )
            console.log(added)
            const imageUrl = `https://ipfs.infura.io/ipfs/${added.path}`
            this.setState({
                imageUrl
            })
            this?.props?.loaderCallback()
        } catch (error) {
            console.log('Error uploading file: ', error);
        }
    }
    uploadToIPFS = async () => {
        if (this.state.imageUrl) {
            const { name, description } = this.state
            const data = JSON.stringify({
                name, description, image: this.state.imageUrl
            })
            try {
                const added = await clientIPFS.add(data)
                const url = `https://ipfs.infura.io/ipfs/${added.path}`
                /* after file is uploaded to IPFS, return the URL to use it in the transaction */
                return url
            } catch (error) {
                console.log('Error uploading file: ', error)
            }
        } else {
            alert("No data found")
        }
    }
    handleInputChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
        console.log(name)
        if (name == "royality" && value > 50) {
            this.setState({
                royalityError: true,
                royality: 0
            })
        } else if(name == "royality") {
            this.setState({
                royalityError: false,
                royality: value
            })
        }
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const { name, description, price, royality, copies } = this.state
        let { nameError, desError, priceError } = ""
        if (!name) {
            nameError = "This field is required."
        }
        if (!description) {
            desError = "This field is required."
        }
        if (!price) {
            priceError = "This field is required."
        }
        this.setState({
            nameError: nameError,
            desError: desError,
            priceError: priceError,
        })
        if (nameError && desError && priceError) {
            return
        }
        this.listNFT();

    }
    listNFT = async () => {
        const url = this.uploadToIPFS()
        const provider = new ethers.providers.Web3Provider(Web3.givenProvider)
        const signer = provider.getSigner()
        const royality = this.state.royality * 100
        let ERC721_Approval = new ethers.Contract(ERC721_ADDRESS, ERC721_ABI,signer)
        await ERC721_Approval.setApprovalForAll(MARKET_PLACE_ADDRESS,true);
        /* next, create the item */
        const price = ethers.utils.parseUnits(this.state.price, 'ether')
        let contract = new ethers.Contract(MARKET_PLACE_ADDRESS, MarketPlaceAbi, signer)
        let transaction = await contract.createNFTAndList(1, url, price, 0, royality)

        // let test  = await transaction.wait()
        console.log(transaction)
    }
    // getUserNFT = async () => {

    //     const provider = new ethers.providers.Web3Provider(Web3.givenProvider)
    //     const signer = provider.getSigner()

    //     const marketplaceContract = new ethers.Contract(MARKET_PLACE_ADDRESS, MarketPlaceAbi, signer)
    //     const data = await marketplaceContract.fetchMarketItems()
    //     console.log(data)
    //     let arr = []
    //     const items = await Promise.all(data.map(async i => {
    //         // console.log(i)
    //         const tokenURI = await marketplaceContract.tokenURI(i.tokenId)
    //         arr.push(tokenURI)
    //         console.log(tokenURI)
    //         // return tokenURI
    //         const meta = await axios.get(tokenURI)
    //         let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
    //         let item = {
    //             price,
    //             tokenId: i.tokenId.toNumber(),
    //             seller: i.seller,
    //             owner: i.owner,
    //             image: meta.data.image,
    //             tokenURI,
    //             name: meta.data.name,
    //             description: meta.data.description
    //         }
    //         return item
    //     }))
    //     console.log(items)
    //     this.setState({ items })
    // }
    render() {

        return (
            <section className="author-area create-item">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-4">
                            {/* Author Profile */}
                            <AuthorProfile />
                        </div>
                        <div className="col-12 col-md-7">
                            {/* Intro */}
                            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                                <div className="intro-content">
                                    <span>Get Started</span>
                                    <h3 className="mt-3 mb-0">Create Item</h3>
                                </div>
                            </div>
                            {/* Item Form */}
                            <form className="item-form card no-hover" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className='uploadImage' >
                                            <img src={this.state.imageUrl} />
                                        </div>
                                        <div className="input-group form-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="inputGroupFile01" onChange={this.onFileUpload} />
                                                <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="name" placeholder="Item Name" required="required" onChange={this.handleInputChange} />
                                            <span className='error text-danger'>{this.state.nameError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea className="form-control" name="description" placeholder="Description" cols={30} rows={3} defaultValue={""} onChange={this.handleInputChange} />
                                            <span className='error text-danger'>{this.state.desError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="price" placeholder="Item Price" required="required" onChange={this.handleInputChange} />
                                            <span className='error text-danger'>{this.state.priceError}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <input type="number" className="form-control" name="royality" placeholder="Royality %" required="required" onChange={this.handleInputChange} value={this.state.royality} />
                                            <small class={`form-text ${this.state.royalityError ? "error text-danger" : "text-muted"}`}>0%, 10%, 20%, 30%. Maximum is 50%</small>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group">
                                            <input type="number" className="form-control" name="copies" placeholder="No of Copies" required="required" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-12 d-none">
                                        <div className="form-group mt-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" defaultChecked />
                                                <label className="form-check-label" htmlFor="inlineRadio1">Put on Sale</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                                                <label className="form-check-label" htmlFor="inlineRadio2">Instant Sale Price</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" defaultValue="option3" />
                                                <label className="form-check-label" htmlFor="inlineRadio3">Unlock Purchased</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" type="submit">Create Item</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Create;