import { toast } from 'react-toastify';
import { create as ipfsHttpClient } from 'ipfs-http-client'

const projectId = "26BZX4wQaPc09CXRXCibB7iPRX4"
const projectSecret = "f9c4093c7c880f390a6444acf310d8fa"

const auth =
    'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
// const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')
export const clientIPFS = ipfsHttpClient({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
})

export const toaster = (type, msg) => {
    if (type === "success") {
        toast.success(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if (type === "error") {
        toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result)
        };
        reader.onerror = function (error) {
            reject(error);
        };
    })
}