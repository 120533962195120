import React, { Component } from 'react';

import Loader from './loader';
import EmailComp from '../components/VerifyEmail/EmailComp';

class EnterEmail extends Component {
    state={
        loading:true
    }
    loader = () =>{
        this.setState({
            loading:!this.state.loading
        })
    }
    render() {
        return (
            <div className="main">
                <Loader class={this.state.loading}/>
                <EmailComp loaderCallback={this.loader}/>
            </div>
        );
    }
}

export default EnterEmail;