import React from "react";

class Loader extends React.Component {
    render() {
        let loading = !this.props.class ? "d-none" : ""
        return (
            <div class={`loader ${loading}`}>
                <img src="/img/loader.svg" alt="" />
            </div>
        )
    }
}
export default Loader;