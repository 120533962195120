export const apiPath = 'api/'

export const APP_HOST = process.env.APP_HOST || 'localhost'
export const APP_PORT = process.env.APP_PORT || 3000
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT
export const HOST = `${APP_HOST}:${SERVER_PORT}/`

// http://127.0.0.1:3000/api
export const API_URL = `http://localhost:3000/api/`
export const JWT_TOKEN = 'token'
export const WALLET_ACCOUNT = 'account'