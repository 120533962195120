import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, Slide, toast } from 'react-toastify';
import { Navbar } from 'react-bootstrap';
import { isAuthenticated } from '../../utils/jwtUtil';
import { fetchEntity } from '../../services/httpService';
import { isWalletConnected } from '../../context/connector';
class Header extends React.Component {
    state = {
        redirect: ""
    }
    componentDidMount() {
        console.log(isAuthenticated(), isWalletConnected())

    }
    walletCheck = () => {
        if (isWalletConnected()) {
            return
        }else{
            return (
                <ul className="navbar-nav action">
                    <li className="nav-item ml-3">
                        <Link to="/wallet-connect" className="btn ml-lg-auto btn-bordered-white"><i className="icon-wallet mr-md-2" />Wallet Connect</Link>
                    </li>
                </ul>
            )
        }
    }
    isAuthenticatedHeader = () => {
        return (
            <>
                {this.walletCheck()}
                <ul className="navbar-nav items mx-auto profile">
                    <li className="nav-item dropdown">
                        <div className="author">
                            <div className="author-thumb avatar-lg">
                                <img className="rounded-circle" alt="" src="/img/avatar_8.jpg" />
                            </div>
                        </div>
                        <ul className="dropdown-menu">
                            <li className="nav-item"><Link to="/settings" className="nav-link">Edit Profile</Link></li>
                            <li className="nav-item"><Link to="/profile" className="nav-link">My Collections</Link></li>
                            <li className="nav-item"><Link to="" onClick={() => this.logout()} className="nav-link">Sign out</Link></li>
                        </ul>
                    </li>
                </ul>
            </>
        )
    }
    login = () => {
        return (
            <ul className="navbar-nav action">
                <li className="nav-item ml-3">
                    <Link to="/login" className="btn ml-lg-auto btn-bordered-white">Login</Link>
                </li>
            </ul>
        )
    }
    navHeader = () => {
        return isAuthenticated() ? this.isAuthenticatedHeader() : this.login()
    }
    logout = async () => {
        const response = await fetchEntity('/logout');
        if (response.status == 200) {
            localStorage.clear()
            window.location.reload()
            window. scrollTo(0, 0)
        }
    }
    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <header id="header">
                <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={Slide}
                />
                {/* Navbar */}
                <Navbar className="navbar navbar-expand" fixed="top">
                    <div className="container header">
                        {/* Navbar Brand*/}
                        <Link className="navbar-brand" to="/">
                            <img className="navbar-brand-sticky" src="img/logo.png" alt="sticky brand-logo" />
                        </Link>
                        <div className="ml-auto" />
                        {/* Navbar */}
                        <ul className="navbar-nav items mx-auto">
                            <li className="nav-item dropdown">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="/auctions" className="nav-link">Live Auctions</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/activity" className="nav-link">Activity</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="#">Pages <i className="fas fa-angle-down ml-1" /></a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a href="/authors" className="nav-link">Authors</a></li>
                                    <li className="nav-item"><a href="/author" className="nav-link">Author</a></li>
                                    {/* <li className="nav-item"><a href="/wallet-connect" className="nav-link">Wallet Connect</a></li> */}
                                    <li className="nav-item"><a href="/create" className="nav-link">Create</a></li>
                                    <li className="nav-item"><a href="/login" className="nav-link">Login</a></li>
                                    <li className="nav-item"><a href="/signup" className="nav-link">Signup</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="/contact" className="nav-link">Contact</a>
                            </li>
                        </ul>
                        {/* Navbar Icons */}
                        <ul className="navbar-nav icons">
                            <li className="nav-item">
                                <a href="#" className="nav-link" data-toggle="modal" data-target="#search">
                                    <i className="fas fa-search" />
                                </a>
                            </li>
                        </ul>
                        {/* Navbar Toggler */}
                        <ul className="navbar-nav toggle">
                            <li className="nav-item">
                                <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                    <i className="fas fa-bars toggle-icon m-0" />
                                </a>
                            </li>
                        </ul>
                        {/* Navbar Action Button */}
                        {this.navHeader()}
                    </div>
                </Navbar>
            </header>
        );
    }
}
// const Header = () => {

// };

export default Header;