import React from "react";
import AuctionsOne from "../Auctions/AuctionsOne";
import { isWalletConnected } from "../../context/connector";
import { MetamaskConnection } from "../../context/connector";
import { isObject } from "../../utils/commonUtil";
import AVONFT_ABI from "../../context/AVONFT_abi.json"
import { MARKET_PLACE_ADDRESS } from "../../constants/entity";
import { ethers } from "ethers";
import Web3 from "web3";
import { Alert } from "react-bootstrap";
class MintNFT extends React.Component {
    state = {
        mint_val: 0,
        error: "",
        metamask: false,
        time: new Date().toLocaleTimeString(),
        hours: 0,
        minutes: 0,
        ampm: 0,
        mint_price: 0.5,
        closed: false,
        mintValError: ''
    }
    componentDidMount() {
        if (isWalletConnected()) {
            this.setState({
                metamask: true
            })
        }
        console.log(this.state.time)
        const time = new Date;
        console.log(time.getUTCHours(), time.getUTCMinutes(), time.getUTCSeconds())
        console.log(this.getTime())
        setInterval(() => {
            this.getTime()
        }, 1000);

    }
    getTime = () => {
        // console.log(new Date.getHours())
        const date = new Date();
        const hours = date.getUTCHours()
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        // console.log(minutes)
        this.setState({
            time: hours + ":" + minutes + ":" + seconds,
        }, () => {
            if (hours >= 20 && hours < 24) {
                this.setState({
                    closed: false
                })
            }
            // if (hours >= 20 && minutes > 15) {
            //     this.setState({
            //         mint_price: 0.5
            //     })
            // }
        })
        // const time = new Date;
        // let hours = 24
        // let minutes = time.getUTCMinutes()
        // let seconds =  time.getUTCSeconds()
        // hours %= 12;
        // let ampm = hours >= 12 ? 'pm' : 'am';
        // // alert(hours)
        // hours = hours || 12;
        // minutes = minutes < 10 ? `0${minutes}` : minutes;
        // this.setState({
        //     hours:hours,
        //     minutes:minutes,
        //     ampm:ampm
        // })
    }
    onChangeHandler = (e) => {
        if (!this.state.closed) {
            const { name, value } = e.target
            if (value >= 0) {
                this.setState({
                    [name]: value,
                    mintValError: ""
                })
            } else {
                this.setState({
                    [name]: 0,
                    mintValError: ""
                })
            }
            if (value > 10) {
                this.setState({
                    [name]: 10,
                    mintValError: ""
                })
            }
        }
    }
    minusMint = () => {
        if (!this.state.closed) {
            this.setState({
                mint_val: this.state.mint_val - 1,
                mintValError: ""
            }, () => {
                if (this.state.mint_val < 0) {
                    this.setState({
                        mint_val: 0,
                    })
                }
            })
        }
    }
    addMint = () => {
        if (!this.state.closed) {
            this.setState({
                mint_val: parseFloat(this.state.mint_val) + 1,
                mintValError: ""
            }, () => {
                if (this.state.mint_val > 10) {
                    this.setState({
                        mint_val: 10,
                    })
                }
            })
        }
    }
    submitHandler = async (e) => {
        e.preventDefault()
        if (!this.state.closed) {
            const { mint_val } = this.state
            let mintValError = ""
            if (!mint_val || mint_val == 0) {
                mintValError = "This field is required"
            }
            if (mintValError) {
                this.setState({
                    mintValError: mintValError
                })
                return
            }
            const accounts = await MetamaskConnection()
            try {

                if (isObject(accounts)) {
                    const provider = new ethers.providers.Web3Provider(Web3.givenProvider)
                    const signer = provider.getSigner()
                    let contract = new ethers.Contract(MARKET_PLACE_ADDRESS, AVONFT_ABI, signer)
                    let a = this.state.mint_price * this.state.mint_val;
                    const price_public = ethers.utils.parseUnits(a.toString(), 'ether')
                    console.log(price_public.toString())
                    let transaction = await contract.mint(this.state.mint_val, { value: price_public })
                    await transaction.wait()
                }
            } catch (error) {
                alert(error.message)
            }
            console.log(accounts)
        } else {
            alert("Mint is Closed")
        }

    }
    connectWallet = () => {
        if (this.state.metamask) {
            return (
                <form onSubmit={this.submitHandler}>
                    <div className="mint-num">
                        <a className="minus-sign btn" onClick={() => this.minusMint()}><i class="fa fa-minus"></i></a>
                        <input type="number" className="" name="mint_val" onChange={this.onChangeHandler} value={this.state.mint_val} />
                        <a className="plus-sign btn" onClick={() => this.addMint()}><i class="fa fa-plus"></i></a>
                        <br />
                    </div>
                    <span className='error text-danger'>{this.state.mintValError}</span>
                    <br />
                    <button type="submit" className={`btn btn-bordered-white mt-5 ${this.state.closed ? "disabled" : ""}`}>Mint Now</button>
                </form>
            )
        } else {
            return (
                <button className="btn btn-bordered-white mt-5" onClick={() => this.connectMetamask()}>Connect Your Wallet</button>
            )
        }
    }
    connectMetamask = async () => {
        const account = await MetamaskConnection()
        console.log(account)
        if (isObject(account)) {
            localStorage.setItem("web3Address", account[0])
            this.setState({
                metamask: true
            })
        }
    }
    render() {
        return (
            <>

                <nav className="navbar">
                    <a className="navbar-brand mx-auto" href="#">
                        <img className="navbar-brand-sticky" src="img/logo.png" alt="sticky brand-logo" />

                    </a>
                </nav>
                <img src="img/BG.jpg" />
                <section className={`text-center pt-0 ${this.state.closed ? "closed" : ""}`}>

                    <div className="">
                        <p className="timmer">{this.state.time}</p>
                        <h3 className="title">Mint {this.state.closed ? "Closed" : "Open"}</h3>
                        <p>Max Qnty:10</p>
                        {this.connectWallet()}
                    </div>
                    <br />
                    <br />
                    <br />
                    <strong>*If on mobile you must navigate to this URL using the browser inside the MetaMask App.</strong>
                    <br />
                    <br />
                    <br />
                    <br />
                    <h3>Thank you for minting</h3>
                </section>
            </>
        )
    }
}
export default MintNFT