import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Wallet from '../components/Wallet/Wallet';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Loader from './loader';

class WalletConnect extends Component {
    state={
        loading:true
    }
    loader = () =>{
        this.setState({
            loading:!this.state.loading
        })
    }
    render() {
        return (
            <div className="main">
                <Loader class={this.state.loading}/>
                <Header />
                <Breadcrumb title="Wallet Connect" subpage="Pages" page="Wallet Connect" />
                <Wallet loaderCallback={this.loader}/>
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default WalletConnect;