import React from "react";
import AuthorProfile from './AuthorProfile';
import Explore from '../Explore/ExploreSix';
import { convertBase64 } from "../../utils/helper";

const initData = {
    pre_heading: "Settings",
    heading: "Edit Profile",
    content: "You can set preferred display name, profile images and manage other personal settings",
    btnText: "Load More"
}

class EditAuthorProfile extends React.Component {
    state = {
        initData: initData,
        base64Img:""
    }

    imageUpload = async(e) =>{
        const value = await convertBase64(e.target.files[0])
        this.setState({
            base64Img: value
        })
    }

    render() {
        return (
            <section className="author-area explore-area popular-collections-area settings">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            <div className="intro text-center">
                                <span>{this.state.initData.pre_heading}</span>
                                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                                <p>{this.state.initData.content}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-8">
                            <form className="item-form no-hover edit-profile">
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <label for="exampleInputEmail1">Display Name</label>
                                        <input type="text" className="form-control" name="name" placeholder="Enter your Display Name" required="required" autocomplete="off" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <label for="exampleInputEmail1">Bio</label>
                                        <textarea className="form-control" name="bio" placeholder="Enter your Bio" required="required" ></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <label for="exampleInputEmail1">Email address</label>
                                        <input type="email" className="form-control" name="email" placeholder="Enter your Email" required="required" autocomplete="off" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="item-form">
                                <div className="author text-center">
                                    <div className="author-thumb avatar-lg m-auto round-img">
                                        <img className="rounded-circle" alt="" src={this.state.base64Img?this.state.base64Img:"/img/avatar_8.jpg"} />
                                    </div>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputGroupFile01" onChange={this.imageUpload}/>
                                        <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 text-center">
                            <button className="btn btn-bordered-white mt-5">Update Profile</button>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default EditAuthorProfile;