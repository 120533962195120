import React from "react";
import { Provider } from 'react-redux';
import { HashRouter, BrowserRouter as Router, Route, useLocation, Navigate, Routes } from 'react-router-dom'
import history from '../utils/history';
import store from "../store/configureStore"
import { isAuthenticated } from "../utils/jwtUtil";

// importing all the themes
import Home from "../themes/home";
import ExploreOne from "../themes/explore-one";
import ExploreTwo from "../themes/explore-two";
import ExploreThree from "../themes/explore-three";
import ExploreFour from "../themes/explore-four";
import Auctions from "../themes/auctions";
import ItemDetails from "../themes/item-details";
import Activity from "../themes/activity";
import Blog from "../themes/blog";
import BlogSingle from "../themes/blog-single";
import HelpCenter from "../themes/help-center";
import Authors from "../themes/authors";
import Author from "../themes/author";
import WalletConnect from "../themes/wallet-connect";
import Create from "../themes/create";
import Login from "../themes/login";
import Signup from "../themes/signup";
import Contact from "../themes/contact";
import Settings from "../themes/Settings";
import VerifyEmail from "../themes/verify-email";
import EnterEmail from "../themes/enter-email";

class MyRouts extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Provider store={store}></Provider>

        <Router history={history}>
          <Routes>
            <Route path="/" element={
              <Guest>
                <Home />
              </Guest>
            } />
            <Route exact path="/explore-1" element={
              <Guest>
                <ExploreOne />
              </Guest>
            } />
            <Route exact path="/explore-2" element={
              <Guest>
                <ExploreTwo />
              </Guest>
            } />
            <Route exact path="/explore-3" element={
              <Guest>
                <ExploreThree />
              </Guest>
            } />

            <Route exact path="/item-details" element={
              <Guest>
                <ItemDetails />
              </Guest>
            } />
            <Route exact path="/auctions" element={
              <Guest>
                <Auctions />
              </Guest>
            } />
            <Route exact path="/author" element={
              <Guest>
                <Author />
              </Guest>
            } />
            <Route exact path="/settings" element={
              <Guest>
                <Settings />
              </Guest>
            } />
            <Route exact path="/verify-email" element={
              <Guest>
                <VerifyEmail />
              </Guest>
            } />
            <Route exact path="/enter-email" element={
              <Guest>
                <EnterEmail />
              </Guest>
            } />

            <Route exact path="/help-center" element={<HelpCenter />} />
            <Route exact path="/authors" element={<Authors />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/contact" element={<Contact />} />

            {/* Required Auth Link */}
            <Route exact path="/profile" element={
              <RequireAuth>
                <Author />
              </RequireAuth>
            } />
            <Route exact path="/create" element={
              <RequireAuth>
                <Create />
              </RequireAuth>
            } />
            <Route exact path="/wallet-connect" element={
              <RequireAuth>
                <WalletConnect />
              </RequireAuth>
          } />

          </Routes>
        </Router>
      </React.Fragment>
    );
  }
}

const RequireAuth = ({ children }) => {

  const location = useLocation()

  if (isAuthenticated()) {
    return children
  }

  return <Navigate to="/" state={{ from: location }} />
}

const Guest = ({ children }) => {

  const location = useLocation()
  // console.log(location);
  return children
  // if (!isAuthenticated()) {
  // }

  // return <Navigate to="/dashboard" state={{ from: location }} />
}
export default MyRouts;